import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IForm } from '@dep/common/interfaces/support';
import { NGXLogger } from 'ngx-logger';
import { lastValueFrom, Subscription } from 'rxjs';

import { environment } from '../../../environments/environment';

import { UserService } from '@dep/frontend/services/user.service';

@Injectable({
  providedIn: 'root',
})
export class SupportFormsService {
  private forms?: IForm[];

  constructor(
    private logger: NGXLogger,
    private http: HttpClient,
    private userService: UserService,
  ) {
    this.clearFormsOnLogout();
  }

  public async getForms(): Promise<IForm[]> {
    if (this.forms !== undefined) {
      return this.forms;
    }

    try {
      const response = await lastValueFrom(this.http.get<IForm[]>(
        environment.config.support.url + '/forms',
        {
          headers: await this.userService.getAuthorizationHeaders(),
        },
      ));
      this.logger.debug('Support forms', response);
      this.forms = response;
      return response;
    } catch (err) {
      this.logger.error('Could not fetch forms', err);
      throw err;
    }
  }

  /**
   * Subscription handler to clear the current forms on logout.
   *
   * @returns Subscription - Subscription the handler registrated.
   */
  private clearFormsOnLogout(): Subscription {
    return this.userService.sessionObservable$.subscribe({
      next: (user) => {
        if (!user) {
          this.forms = undefined;
        }
      },
    });
  }
}
